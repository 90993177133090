<template>
    <div class="other-method-tab">
        <CardOther
            v-for="other in otherPsps"
            :key="other.id"
            :other="other"
        />
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import CardOther from "@/new-design/entities/Deposit/ui/CardOther.vue"

export default {
    name: "OtherMethodTab",
    components: {CardOther},
    computed: {
        ...mapGetters(['otherPsps'])
    }
}
</script>

<style scoped>
.other-method-tab {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}
</style>
