<script>
import BaseAccountType from "@/new-design/shared/ui/account-type/BaseAccountType.vue"
import BaseLogo from "@/new-design/shared/ui/Icons/BaseLogo.vue"
import {mapGetters} from "vuex"
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type";
import {LOGO, TRADINGVIEW_THEME, WHITE_ASPIN_THEME} from "@/common/config"
import BaseAutotraderButton from "@/new-design/shared/ui/buttons/BaseAutotraderButton.vue"
import BaseDepositButton from "@/new-design/shared/ui/buttons/BaseDepositButton.vue"
import BaseSettingIcon from "@/new-design/shared/ui/Icons/BaseSettingIcon.vue"
import BaseMonogram from "@/new-design/shared/ui/Icons/BaseMonogram.vue"
import BaseActiveAccountButton from "@/new-design/shared/ui/buttons/BaseActiveAccountButton.vue"
import BaseToggleIconButton from "@/new-design/shared/ui/buttons/BaseToggleIconButton.vue"

export default {
    name: "TheHeader",
    components: {
        BaseToggleIconButton,
        BaseActiveAccountButton,
        BaseMonogram, BaseSettingIcon, BaseDepositButton, BaseAutotraderButton, BaseLogo, BaseAccountType
    },
    data() {
        return {
            tradingViewTheme: TRADINGVIEW_THEME,
            whiteAspinTheme: WHITE_ASPIN_THEME,
            logo: LOGO,
        }
    },
    methods: {
        showMenu() {
            let state = this.modals.sideMenuBurger;
            this.$store.commit(MODAL, {
                sideMenuBurger: !state
            })
        },
        openBalanceModal() {
            this.$store.commit(MODAL, {
                balance: true
            })
        },
        showCabinet(page) {
            this.$store.commit(MODAL, {
                fullscreen: true,
            })
            this.$store.commit(MODAL_FULLSCREEN, {
                page
            })
        },
        showAutoTraderModal() {
            this.$store.commit(MODAL, {
                autoTrader: true
            })
        }
    },
    computed: {
        ...mapGetters(['staticBaseDomain', 'lightTheme', 'tokens', 'accounts', 'activeAccount', 'modals']),
        logoComputed() {
            let url = this.logo.url

            if (this.staticBaseDomain !== 'co') {
                const parts = url.split('/')
                parts[2] = parts[2].replace('co', this.staticBaseDomain)
                url = parts.join('/')
            }

            if (this.whiteAspinTheme) {
                return this.lightTheme ? url.replace(/white/, 'black') : url
            } else {
                return this.tradingViewTheme === 'Light' ? url.replace(/white/, 'black') : url
            }
        }
    }
}
</script>

<template>
    <header class="header">
        <div class="row left-row">
            <BaseToggleIconButton class="burger" :button-style="'secondary'" @update="showMenu">
                <template #init-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M3 18.5V16.5H21V18.5H3ZM3 13.5V11.5H21V13.5H3ZM3 8.5V6.5H21V8.5H3Z" fill="#BFC6DC"/>
                    </svg>
                </template>
                <template #active-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M3 18.5V16.5H21V18.5H3ZM3 13.5V11.5H21V13.5H3ZM3 8.5V6.5H21V8.5H3Z" fill="#AFC6FF"/>
                    </svg>
                </template>
            </BaseToggleIconButton>
            <BaseLogo class="header-logo" :src="logoComputed" :height="logo.height">
                <template v-if="!logoComputed" #logo-placeholder>
                    <span class="brand">{{ tokens.header.cfd }}</span>
                    {{ tokens.header.platform }}
                </template>
            </BaseLogo>
        </div>
        <div class="row right-row">
            <BaseAutotraderButton
                v-tooltip="'Auto Trader'"
                @click.native="showAutoTraderModal"
                class="autotrader"/>
            <div class="account-box">
                <BaseAccountType :accountType="activeAccount.group"/>
                <BaseActiveAccountButton
                    :balance="activeAccount.balance.toFixed(2).toString()"
                    :symbol="activeAccount.currency"
                    @click.native="openBalanceModal"/>
                <BaseDepositButton/>
            </div>
            <div class="profile">
                <BaseSettingIcon class="settings"/>
                <BaseMonogram
                    v-tooltip="'Your profile and settings'"
                    @click.native="showCabinet('dashboard')" />
            </div>
        </div>
    </header>
</template>

<style scoped>
.row {
    display: flex;
    align-items: center;
}

.header {
    flex: 0 0 56px;
    display: flex;
    align-items: center;
    padding: 10px 8px;
    border-bottom: 1px solid var(--border);
    justify-content: space-between;
    background: var(--System-Surface);
}

.left-row {
    overflow: hidden;
    flex: 1 1 auto;
    flex-wrap: nowrap;
}

.right-row {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
}

.profile,
.account-box {
    display: flex;
    align-items: center;
}

.account-box {
    gap: 16px;
    border-left: 1px solid var(--System-OutlineVariant);
    padding-left: 16px;
    margin: 0 24px;
}

.profile {
    gap: 28px;
}

.settings {
    cursor: pointer;
}

.brand {
    color: var(--brand) !important;
}

.burger {
    display: none;
}

@media (max-width: 1023px) {
    .burger {
        display: inline-flex;
        margin-right: 4px;
    }

    .account-type {
        display: none;
    }
}
</style>
