<template>
    <div class="search-currency-select-wrapper">
        <div v-if="title" class="title body__medium">{{ title }}</div>
        <div class="search-currency-select">
            <input v-model="searchedCurrency" placeholder="Search currency" class="search-currency-input body__large"
                @focusout="chooseCurrency(searchedCurrency)"
                @focus="isFilterList = false"
                />
                <div class="selected-symbol symbol" :class="`symbol-${searchedCurrency}`"></div>

            <i class="material-symbols-outlined">arrow_drop_down</i>
            <BaseMenuList class="search-currency-list" :style="{
                'max-height': `${(maxShowed * 36) + 8}px`,
                'display': currenciesList.length > 0 ? '' : 'none'
            }">
                <template #content>
                    <BaseMenuListItem v-for="(currencyItem, index) in currenciesList" :key="index"
                        :class="{ active: currencyItem === currency }" :tag="'li'"
                        @mousedown.native="chooseCurrency(currencyItem)">
                        <template #content>
                            <div class="option">
                                <div class="option-symbol symbol" :class="`symbol-${currencyItem}`"></div>
                                <span>{{ currencyItem }}</span>
                            </div>
                        </template>
                    </BaseMenuListItem>
                </template>
            </BaseMenuList>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import BaseMenuList from "@/new-design/shared/ui/panels/BaseMenuList.vue"
import BaseMenuListItem from "@/new-design/shared/ui/panels/BaseMenuListItem.vue"

export default {
    name: "SearchCurrencySelect",
    components: { BaseMenuList, BaseMenuListItem },
    props: {
        maxShowed: {
            type: Number,
            default: 5
        },
        value: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            searchedCurrency: this.vaildatePropsValue(this.value),
            isFilterList: false
        }
    },
    computed: {
        ...mapGetters(['currencies']),
        currenciesList() {
            return !this.isFilterList ? (this.currencies) : (this.currencies).filter(currency => currency.toLowerCase().indexOf(this.searchedCurrency.toLowerCase()) > -1)
        },
        currency() {
            return this.vaildatePropsValue(this.value)
        }

    },
    methods: {
        chooseCurrency(currency) {
            if ((this.currencies).includes(currency)) {
                if (this.currency === currency) return;
                this.searchedCurrency = currency
                this.$emit('update', this.searchedCurrency)
            } else {
                this.searchedCurrency = this.currency
            }
        },
        vaildatePropsValue(value) {
            return this.currencies && (this.currencies).includes(value) ? value : ''
        }
    },
    watch: {
        currencies() {
            this.searchedCurrency = this.vaildatePropsValue(this.value)
        },
        searchedCurrency(){
            this.isFilterList = true
        }
    }
}
</script>

<style scoped>
.title {
    display: flex;
    padding-bottom: 4px;
}

.search-currency-select {
    position: relative;
}

.search-currency-select i {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    transition: transform .3s ease;
}

.search-currency-input:focus~i {
    transform: rotate(180deg)
}

.search-currency-input {
    padding: 8px 30px 8px 40px;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
}

.search-currency-input::placeholder {
    color: var(--System-Outline);
}

.search-currency-input:focus {
    border-color: var(--System-Primary);
}

.search-currency-list {
    display: none;
    position: absolute;
    top: 44px;
    width: 100%;
    overflow-x: auto;
    color: var(--System-OnSurface);
    padding: 8px 0;
    border-radius: 4px;
    background: var(--System-Surface3);
    flex-direction: column;
    z-index: 20;
}

.search-currency-input:focus~.search-currency-list {
    display: flex;
}

.search-currency-list p {
    padding: 8px 10px;
    cursor: pointer
}

.search-currency-list p.active,
.search-currency-list p:hover {
    background: var(--Statelayers-primary-opacity16);
}

.option {
    display: flex;
    align-items: center;
    gap: 8px;
}

.selected-symbol {
    position: absolute;
    top: 30px;
    left: 25px;
    zoom: .35
}

.option-symbol {
    zoom: .25
}
</style>