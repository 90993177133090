<template>
    <div class="investment-details">
        <div class="investment-details-input">
            <BaseInput 
                v-model="searchedValue" 
                :title="`Select a currency and a subscription period`">
                <template #icon>
                    <i class="material-symbols-outlined">search</i>
                </template>
            </BaseInput>
        </div>
        <div v-if="filteredStakingCurrencies.length" class="investment-details-list">
            <div class="item" 
                v-for="staking in filteredStakingCurrencies" 
                :key="staking.currency"
                :class="{ active: staking.currency === currencyChosen }">
                    <header @click="$emit('updateChosenStaking', staking)">
                        <div class="currency">
                            <div class="image"><img :src="staticBase + `currencies/${staking.currency}.png`"></div>
                            <div class="name body__large"> {{ staking.currency }}</div>
                        </div>
                        <div class="rates body__large">
                            {{ staking.pct_0 && staking.pct_365 ? 
                                `${staking.pct_0.toFixed(1)} - ${staking.pct_365.toFixed(1)} %` : '0 %' }}
                        </div>
                        <i class="material-icons">
                            {{ currencyChosen === staking.currency ? 
                                'arrow_drop_up' : 'arrow_drop_down'}}
                        </i>
                    </header>
                    <div class="periods" v-show="staking.currency === currencyChosen">
                        <div class="period" 
                            v-for="period in periods" 
                            :key="period"
                            :class="{ active: period === periodChosen }"
                            @click="$emit('updatePeriodChosen', period)" >
                            <div class="period-name">
                                <BaseRadioButton :state="period === periodChosen" :hoverDisable="true" />
                                <span class="label__largeprominent">{{ tokens.fullScreenSavings[period] }}</span>
                            </div>
                            <span class="period-rate body__large">{{ staking[period].toFixed(1) }}%</span>
                        </div>
                    </div>
            </div>
        </div>
        <div v-else class="not-found body__large">
            Not Founded
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput"
import BaseRadioButton from "@/new-design/shared/ui/Inputs/BaseRadioButton"

export default {
    name: 'InvestmentDetailsTab',
    components: {
        BaseInput,
        BaseRadioButton,
    },
    props: {
        periodChosen: {
            type: String
        },
        currencyChosen: {
            type: String
        },
    },
    data() {
        return {
            searchedValue: '',
            periods: ['pct_0', 'pct_30', 'pct_90', 'pct_180', 'pct_365'],
        }
    },
    computed: {
        ...mapGetters(['tokens', 'symbols', 'staticBase', 'stakingCurrencies']),

        filteredStakingCurrencies() {
            return Object.values(this.stakingCurrencies).filter(staking => staking.currency.toLowerCase().includes(this.searchedValue.toLowerCase()))
        },
    }
}
</script>

<style scoped>
.investment-details {
    max-height: 100%;
    overflow: hidden;
}

.investment-details-input {
    padding-inline: 16px;
    margin-bottom: 17px;
}

.investment-details-list {
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 16px;
    height: calc(100% - 75px);
    overflow-y: auto;
}

.item {
    margin-bottom: 12px;
    border-radius: 8px;
    overflow: hidden;
}

.item header {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    cursor: pointer;
    background: var(--System-Surface1);
}

.item.active header {
    background: #2e3644;
}

.currency {
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 10px;
}

.image img {
    height: 24px;
}

.name {
    color: var(--System-OnSurface);
}

.rates {
    color: var(--System-Outline);
    margin: 0 10px 0 20px;
    white-space: nowrap;
}

.period {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 12px 20px 12px 10px;
    background: var(--System-Surface1);
    cursor: pointer;
}

.period:first-child {
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.period:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.period-name {
    display: flex;
    align-items: center;
    gap: 5px;
}

.period-rate {
    color: var(--System-OnSurface);
}

.not-found {
    color: var(--System-OnSurface);
    text-align: center;
}
</style>