<template>
    <div class="modal-wrapper" :class="{ hide: !modals.register }">
        <BaseLogo class="aspin-logo" :src="logoComputed" :height="logo.height">
            <template v-if="!logoComputed" #logo-placeholder>
                <span class="brand">{{ tokens.header.cfd }}</span>
                {{ tokens.header.platform }}
            </template>
        </BaseLogo>
        <div class="modal-inner">
            <div class="greeting-title title__medium">Welcome to the trading platform</div>
            <div class="modal">
                <div class="title-wrapper">
                    <BaseModalTitle>
                        <template #left>
                            <div class="modal-title-left">
                                <i class="material-icons">app_registration</i>
                                <span class="title__medium">{{ 'Registration' }}</span>
                            </div>
                        </template>
                        <template #right>
                            <div class="modal-title-right">
                                <span class="label__medium">{{ tokens.loginModal.haveAccount }}</span>
                                <BaseButtonSmall 
                                    :buttonText="'Sign In'" 
                                    :buttonStyle="'tonal-secondary'"
                                    @click.native="goToLoginPage" />
                            </div>
                        </template>
                    </BaseModalTitle>
                </div>
                <div class="modal-box">
                    <BaseInput 
                        v-model="email" 
                        name="email" 
                        :title="'Email'" 
                        :placeholder="tokens.registerModal.email" @keyup.native.enter="register" />
                    <div class="name-inputs">
                        <BaseInput 
                            v-model="firstname" 
                            :title="tokens.registerModal.firstName"
                            :placeholder="'First Name'"
                            @keyup.native.enter="register" />
                        <BaseInput 
                            v-model="lastname" 
                            :title="tokens.registerModal.lastName" 
                            :placeholder="'Last Name'"
                            @keyup.native.enter="register" />
                    </div>
                    <SearchCountrySelect 
                        :title="'Country'" 
                        :value="country" 
                        @update="chooseCountry" />
                    <BaseInput 
                        v-model="phone" 
                        name="phone" 
                        autocomplete="off" 
                        :title="tokens.registerModal.phone" 
                        :type="'text'"
                        :placeholder="tokens.registerModal.phone"
                        @keyup.native="filPhoneNumber" @keyup.native.enter="register" />
                    <BaseInput 
                        v-model="password" 
                        name="password"
                        autocomplete="off" 
                        :title="tokens.registerModal.password"
                        :type="'password'" 
                        :placeholder="tokens.loginModal.yourPassword"
                        @keyup.native.enter="register" />
                    <BaseInput 
                        v-model="password2" 
                        name="password"
                        autocomplete="off" 
                        :title="tokens.registerModal.repeatPassword" 
                        :type="'password'" 
                        :placeholder="tokens.loginModal.yourPassword"
                        @keyup.native.enter="register" />
                    <SearchCurrencySelect 
                        :maxShowed="4"
                        :value="currencyChosen" 
                        @update="currencyChosen = $event" :title="'Currency'"/>
                    <BaseInput 
                        v-if="existPromo" 
                        v-model="manager_id" 
                        :title="'Promo Code'" />
                </div>
                <div class="modal-footer">
                    <BaseButton 
                        :buttonStyle="'filled-primary'" 
                        :buttonText="tokens.registerModal.createAccount"
                        @click.native="register" 
                        @keydown.native.enter="register" />
                </div>
                <div class="modal-footer-additional">
                    <label 
                        class="promo label__medium"
                        @click="existPromo = !existPromo" 
                        >{{ tokens.loginModal.havePromo }}
                    </label>
                    <div v-if="privacyAndPolicyLink" class="privacy-policy">
                        <a :href="privacyAndPolicyLink" 
                            class="label__medium">{{ tokens.registerModal.privacyAndPolicy }}</a>
                    </div>
                    <div v-if="customerAgreementLink" class="customer-agreement">
                        <BaseCheckbox  
                            @click.native="customerAgreementLinkCheckbox = !customerAgreementLinkCheckbox" />
                        <a :href="customerAgreementLink" 
                            download 
                            target="_blank" 
                            class="label__medium">
                            {{ tokens.registerModal.customerAgreement }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { REGISTER } from "@/store/actions.type";
import { NOTIFICATION_ERROR } from '@/store/mutations.type'
import { LOGO, NEW_DESIGN, ROLE, TRADINGVIEW_THEME, WEBSITE, LOGO_REDIRECT_OFF, HIDE_LOGO, PRIVACY_POLICY_LINK, CUSTOMER_AGREEMENT_LINK } from "@/common/config";
import { countryToPhoneCode, hidePass, showPass } from "@/common/helpers";
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton";
import SearchCountrySelect from '@/new-design/shared/ui/selects/SearchCountrySelect'
import SearchCurrencySelect from '@/new-design/shared/ui/selects/SearchCurrencySelect'
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall";
import BaseModalTitle from "@/new-design/shared/ui/titles/BaseModalTitle";
import BaseLogo from "@/new-design/shared/ui/Icons/BaseLogo";
import BaseCheckbox from "@/new-design/shared/ui/Inputs/BaseCheckbox";

export default {
    name: "RegisterModal",
    components: {
        BaseInput,
        BaseButton,
        SearchCountrySelect,
        SearchCurrencySelect,
        BaseButtonSmall,
        BaseModalTitle,
        BaseLogo,
        BaseCheckbox
    },
    mounted() {
        if (this.newDesign) {
            this.$set(this, 'phone', "+44")
            this.$set(this, 'flag', "gb")
        }
    },
    data() {
        return {
            newDesign: NEW_DESIGN,
            firstname: '',
            lastname: '',
            country: '',
            email: '',
            password: '',
            password2: '',
            manager_id: '',
            existPromo: false,
            role: ROLE,
            currencyChosen: 'USD',
            phone: '',
            flag: '',
            website: WEBSITE,
            tradingViewTheme: TRADINGVIEW_THEME,
            logo: LOGO,
            logoRedirectOff: LOGO_REDIRECT_OFF,
            hideLogo: HIDE_LOGO,
            privacyAndPolicyLink: PRIVACY_POLICY_LINK,
            customerAgreementLink: CUSTOMER_AGREEMENT_LINK,
            customerAgreementLinkCheckbox: false
        }
    },
    methods: {
        showPass,
        hidePass,
        filPhoneNumber() {
            if (this.phone === '' && this.newDesign) {
                this.phone += "+"
            }
        },
        chooseCountry(country) {
            this.country = country
            this.setPhone(country)
        },
        setPhone(country) {
            if (this.newDesign) {
                let selectedCountry = countryToPhoneCode(country)
                if (selectedCountry) {
                    this.$set(this, 'phone', selectedCountry[1])
                    this.$set(this, 'flag', selectedCountry[2].toLowerCase())
                }
            }
        },
        register() {
            if (!this.customerAgreementLinkCheckbox && this.customerAgreementLink) {
                this.$store.commit(NOTIFICATION_ERROR, 'Please confirm customer agreement')
                return
            }

            if (!this.email) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_EMAIL')
                return
            }

            if (!this.phone) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_PHONE')
                return
            }

            let manager_id = parseInt(this.manager_id)

            if (isNaN(manager_id) || manager_id <= 0) {
                manager_id = 0
            }

            if (manager_id) {
                this.role = 1
            }

            this.$store.dispatch(REGISTER, {
                firstname: this.firstname,
                lastname: this.lastname,
                country: this.country,
                phone: this.phone,
                email: this.email.trim(),
                currency: this.currencyChosen,
                password: this.password.trim(),
                manager_id,
                role: this.role
            })
        },
        goToLoginPage() {
            window.location.href = '/';
        },
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'currencies', 'staticBase']),
        logoComputed() {
            return this.tradingViewTheme === 'Light' ? this.logo.url.replace(/white/, 'black') : this.logo.url
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aspin-logo {
    display: inline-block;
    width: max-content;
    text-align: center;
    margin: 0 0 35px;
    position: absolute;
    top: 12px;
    left: 10px;
}

.modal-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    top: 15px;
    max-height: 90%;
    height: calc(100% - 70px);
    width: 620px;
}

.modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 25px 28px;
    z-index: 1100;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
    background: var(--System-Surface1);
    position: relative;
    overflow-y: auto;
}

.greeting-title {
    color: var(--System-OnSurfaceVariant);
    position: absolute;
    top: -34px;
    left: 0
}

.title-wrapper {
    margin-bottom: 34px;
}

.modal-title-left {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-title-left i {
    font-size: 18px;
}

.modal-title-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
}

.name-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-footer button {
    margin-left: auto;
}

.modal-forgot {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-footer-additional {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.customer-agreement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.promo {
    margin: 10px 0;
    cursor: pointer;
    text-decoration: underline dotted var(--System-OnSurfaceVariant);
    color: var(--System-OnSurfaceVariant);
}

.privacy-policy a,
.customer-agreement a{
    color: var(--System-OnSurfaceVariant);
}

@media(max-width: 1024px) {
    .modal-inner {
        top: 35px;
        height: calc(100% - 110px);
    }
}

@media(max-width: 768px) {
    .modal-inner {
        margin-inline: 32px;
    }
}
</style>
