<script>
export default {
    name: "BaseWidgetTitle",
    props: {
        title: {
            type: String,
        },
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<template>
    <div class="title">
        <div class="row row-left">
            <slot name="back"/>
            <h4 class="title__medium"> {{ title }}</h4>
        </div>
        <i class="material-icons close" @click="close()">{{ 'close'}}</i>
    </div>
</template>

<style scoped>
.title {
    padding: 18px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--System-OnSurface);
}

.row-left {
    gap: 8px;
}

i {
    cursor: pointer;
}
</style>
