<template>
    <BasePanel class="bank-card-tab">
        <template #content>
            <div v-if="!showSuccessful" class="info">
                <h5 class="title title__medium">Choose a Cryptocurrency Wallet</h5>
                <div class="methods">
                    <BaseSmallChip
                        v-for="(psp, key) in getMethods"
                        :key="key"
                        :text="psp.name"
                        :class="{ active: active.key === psp.key }"
                        @click.native="changeTab(psp)"
                    />
                </div>
                <CardMethod
                    v-if="active && active.type === 'card'"
                    :currencies="currencies"
                    :method="active.name"
                    @update="makeDeposit"
                />
                <BankTransferMethod
                    v-else-if="active && active.type === 'bank'"
                    :currencies="currencies"
                    :method="active.name"
                    @update="generateInvoice"
                />
                <SinglePspMethod
                    v-else
                    :currencies="currencies"
                    :method="active"
                    @update="makeDeposit"
                />
            </div>
            <SuccessfulPanel
                v-else
                :details="getDetails"
                @continue="successful = false"
                @showAccounts="showAccounts"
            />
        </template>
    </BasePanel>
</template>

<script>
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import BaseSmallChip from "@/new-design/shared/ui/chips/BaseSmallChip.vue"
import {mapGetters} from "vuex"
import {pspMethods} from "@/common/psp-list"
import CardMethod from "@/new-design/features/Deposits/BankCardMethods/ui/CardMethod.vue"
import SinglePspMethod from "@/new-design/features/Deposits/BankCardMethods/ui/SinglePspMethod.vue"
import BankTransferMethod from "@/new-design/features/Deposits/BankCardMethods/ui/BankTransferMethod.vue"
import SuccessfulPanel from "@/new-design/features/Deposits/BankCardMethods/ui/SuccessfulPanel.vue"
import {MODAL, MODAL_FULLSCREEN, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {currencySign} from "@/common/helpers"
import {MAXIMUM_DEPOSIT, MINIMUM_DEPOSIT, VARIABLES} from "@/common/config"
import {DEPOSIT_REQUEST, GENERATE_INVOICE} from "@/store/actions.type"

export default {
    name: "BankCardTab",
    components: {
        SuccessfulPanel, BankTransferMethod, SinglePspMethod, CardMethod, BaseSmallChip, BasePanel
    },
    data() {
        return {
            selectedMethod: '',
            selectedCurrency: '',
            selectedCurrencies: null,
            showSuccessful: false,
            successful: false,
            depositInfo: null,
            showAmount: true,
            showPSP: false,
            varNames: VARIABLES,
            cardData: null
        }
    },
    methods: {
        changeTab(tab) {
            this.active = tab
            this.currencies = tab.currencies
            this.currency = this.currencies[0]
        },
        askOtpCode() {
            this.$store.commit(MODAL, {
                otpCode: true
            })
        },
        generateInvoice(data) {
            let minimum = this.user.min_deposit || MINIMUM_DEPOSIT,
                maximum = MAXIMUM_DEPOSIT

            if (parseInt(this.amount) * this.conversions[this.bankCurrencyChosen] < minimum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum deposit is $' + minimum)
                return
            }

            if (parseInt(this.amount) * this.conversions[this.currency] > maximum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Maximum deposit is $' + maximum)
                return
            }

            this.$store.dispatch(GENERATE_INVOICE, {
                psp: 'bank',
                amount: data.info.amount,
                currency: data.info.currency,
                account_id: this.activeAccount.id,
                product: this.activeAccount.product
            })
        },
        makeDeposit(data) {
            data = data.info
            let psps = ['payonera', 'bmopay', 'alwayspay_master', 'alwayspay_visa', 'pinpay', 'checkoutchamp', 'chargemoney']
            let amount

            if (data && this.active.type === 'card') {
                this.cardData = data
                this.amount = data.amount
                this.currency = data.currency
            }

            switch (this.active.key) {
                case 'pinpay':
                    amount = parseFloat(data.amount).toFixed(2).toString()
                    break
                default:
                    amount = String(data.amount)
            }
            let pendingDepositCounter = 0
            amount = amount.includes(',') ? amount.replace(/,/, '.') : amount
            amount = parseFloat(amount) || 0

            let moreInfo = ''
            let card,
                cvv,
                wallet,
                minimum = !this.pspList.filter(data => data.name !== undefined && data.name === this.active.key).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.active.key)[0].deposit_min || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.active.key).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.active.key)[0].deposit_max || MAXIMUM_DEPOSIT


            if (this.user && this.user.min_deposit !== 0) {
                minimum = this.user.min_deposit
            }

            if (this.activeAccount.demo) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_DEPOSIT_ONLY_REAL')
                return
            }

            if (amount * this.conversions[this.currency] < minimum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum deposit is $' + minimum)
                return
            }

            if (amount * this.conversions[this.currency] > maximum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Maximum deposit is $' + maximum)
                return
            }

            if (amount <= 0) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_DEPOSIT_POSITIVE')
                return
            }

            if (this.active.key === 'solidpayment') {
                if (!this.user.address) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Please complete your address')
                    return
                }
                if (!this.user.country) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Please complete your country')
                    return
                }
                if (this.brand === 'titancapitalpartners') {
                    if (!this.user.state) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Please complete your city')
                        return
                    }
                    if (!this.user.postcode) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Please complete your ZIP code')
                        return
                    }
                }
            }

            let current, win

            switch (this.active.key) {
                case 'nax':
                case 'cryptocurrency':
                    break
                case 'cc-ext':
                case 'bmopay':
                case 'pinpay':
                case 'payonera':
                case 'checkoutchamp':
                case 'chargemoney':
                case 'alwayspay_visa':
                case 'alwayspay_master':
                    for (let n in this.deposits) {
                        if (this.deposits[n].status === 0) {
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    if (!Number(this.variables.CFD_DISABLE_CARD_VALIDATION)) {
                        if (!this.$cardFormat.validateCardNumber(this.cardData.cardNumber.replaceAll(' ', ''))) {
                            this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CARD_NUMBER')

                            return
                        }
                    }

                    if (!Number(this.variables.CFD_DISABLE_CARD_VALIDATION)) {
                        if (!this.$cardFormat.validateCardExpiry(this.cardData.month + '/' + this.cardData.year)) {
                            this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CARD_NUMBER')

                            return
                        }
                    }

                    if (!Number(this.variables.CFD_DISABLE_CARD_VALIDATION)) {
                        if (!this.$cardFormat.validateCardCVC(this.cardData.cvv)) {
                            this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CVC')

                            return
                        }
                    }

                    this.cardData.month = ('0' + String(this.cardData.month)).slice(-2)

                    card = this.cardData.cardNumber.replaceAll(' ', '')
                    cvv = this.cardData.cvv
                    if (this.moreCardInfo) {
                        moreInfo = ' \n\nAddress: ' + this.cardData.address + '\n\nTown: ' + this.cardData.town + '\n\nCounty: ' + this.cardData.county
                            + '\n\nZIP: ' + this.cardData.zip
                    }
                    wallet = 'Name: ' + this.cardData.name + '\nCard number: ' + card + '\nExpiration: ' + this.cardData.month + '/' + this.cardData.year + '\nCVV: ' + cvv + moreInfo
                    if (this.active.key === 'cc-ext') {
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: 'cc-ext',
                            amount: amount,
                            currency: this.currency,
                            account_id: this.activeAccount.id,
                            product: this.activeAccount.product,
                            wallet
                        })
                        this.askOtpCode()
                    }

                    if (psps.includes(this.active.key)) {
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: this.active.key,
                            amount: this.active.key === 'pinpay' ? amount.toFixed(2) : amount,
                            currency: this.currency,
                            account_id: this.activeAccount.id,
                            product: this.activeAccount.product,
                            card: {
                                num: parseInt(card),
                                year: parseInt(this.cardData.year),
                                month: parseInt(this.cardData.month),
                                cvv: parseInt(cvv)
                            }
                        })
                    }

                    break
                case 'cc-ext2':
                    this.showAmount = false
                    this.showPSP = true
                    this.askOtpCode()
                    break
                case 'cc-ext3':
                    current = this.pspConfig[this.active.key].onlyButtons.find((i) => i.value == data.amount)
                    if (current) {
                        win = window.open(current.link, '_blank')

                        if (win) {
                            win.focus()
                        }
                    }
                    break
                case 'realeasypay':
                case 'cascading':
                case 'finanic':
                case 'betatransfer':
                case 'prmoney':
                case 'starbill_usd':
                case 'starbill_eur':
                case 'paypound':
                case 'kryptova':
                case 'walletix':
                case 'walletcomru':
                case 'payzon':
                case 'bitexbit':
                case 'paystudio':
                case 'stripe':
                case 'settlepay_usd':
                case 'settlepay_eur':
                case 'settlepay_kzt':
                case 'garrypay':
                case 'texcent':
                case 'billing_ru':
                case 'billing_world':
                case 'honeypay':
                case 'square':
                case 'wellex':
                case 'villpay':
                case 'payhub':
                case 'ppay':
                case 'pinikle':
                case 'enot':
                case 'decard':
                case 'p2p_bank':
                case 'milkypay':
                case 'prmoney_ru':
                case 'prmoney_eur':
                case 'todapay':
                case 'payretailers':
                case 'dikeshield':
                case 'dikeshield_smartpay':
                case 'alfakit':
                case 'momentex':
                case 'betspacemoney':
                case 'betspacemoney_card':
                case 'paycos':
                case 'storsunrise':
                case 'payepo':
                case 'solidpayment':
                case 'payepo_world':
                case 'transfertum':
                case 'paypecker':
                    for (let n in this.deposits) {
                        if (this.deposits[n].status === 0) {
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: this.active.key,
                        amount: amount.toString(),
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id
                    })
                    break
            }
        },
        showAccounts() {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL, {
                hamburgerMenu: false
            })

            this.$store.commit(MODAL_FULLSCREEN, 'accounts')
        },
    },
    computed: {
        getDetails() {
            if (!this.depositInfo) {
                return
            }

            let details = [
                {
                    title: 'Amount deposit',
                    value: currencySign(this.depositInfo.currency) + ' ' + this.depositInfo.amount,
                },
                {
                    title: 'Currency',
                    value: this.depositInfo.currency,
                },
                {
                    title: 'Time',
                    value: this.formattedDepositTime,
                },
                {
                    title: 'Transaction status',
                    value: 'Processing',
                },
            ];

            if (this.depositInfo.type === 'card') {
                details.splice(1, 0,
                    {
                        title: 'Payment method',
                        value: this.depositInfo.name,
                    },
                    {
                        title: 'Deposit to',
                        value: this.formattedCardNumber,
                    }
                )
            }

            return details
        },
        formattedCardNumber() {
            if (!this.cardNumber) {
                return
            }

            const lastFourDigits = this.cardNumber.trim().slice(-4)
            return `**** **** **** ${lastFourDigits}`
        },
        formattedDepositTime() {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
            return new Date().toLocaleString('en-GB', options).replace(',', '')
        },
        getMethods() {
            const filteredMethods = Object.entries(pspMethods()).filter(([key, method]) => {
                switch (method.type) {
                    case 'bank':
                        return this.bankConfig.length
                    default:
                        return this.pspList.some(psp => psp.name === key)
                }
            }).map(([key, method]) => ({key, ...method}))

            return filteredMethods.sort((a, b) => {
                if (a.order === -1) return 1
                if (b.order === -1) return -1
                return a.order - b.order
            })
        },
        active: {
            get() {
                return this.selectedMethod || (this.getMethods.length ? this.getMethods[0] : '')
            },
            set(value) {
                this.selectedMethod = value
            }
        },
        currencies: {
            get() {
                return this.selectedCurrencies ? this.selectedCurrencies : this.getMethods[0].currencies || []
            },
            set(value) {
                this.selectedCurrencies = value
            }
        },

        ...mapGetters(['pspList', 'bankConfig', 'user', 'activeAccount', 'deposits', 'variables', 'conversions'])
    }
}
</script>


<style scoped>
.title {
    color: var(--System-OnSurface);
    font-weight: 500;
}

.methods {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
}
</style>
