<script>
import toggler from "@/new-design/shared/mixins/toggler"

export default {
    name: "BaseCheckbox",
    props: {
        checkboxType: {
            type: String
        }
    },
    mixins: [toggler]
}
</script>

<template>
    <div class="checkbox" @click="state = !state" :class="checkboxType">
        <svg v-if="!state" class="default" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M4.16666 18.0417C3.5593 18.0417 3.03944 17.8255 2.60708 17.393C2.17458 16.9606 1.95833 16.4407 1.95833 15.8334V4.16671C1.95833 3.55949 2.17458 3.03962 2.60708 2.60712C3.03944 2.17462 3.5593 1.95837 4.16666 1.95837H15.8333C16.4406 1.95837 16.9604 2.17462 17.3929 2.60712C17.8254 3.03962 18.0417 3.55949 18.0417 4.16671V15.8334C18.0417 16.4407 17.8254 16.9606 17.3929 17.393C16.9604 17.8255 16.4406 18.0417 15.8333 18.0417H4.16666ZM4.16666 15.8334H15.8333V4.16671H4.16666V15.8334Z"
                fill="#9A9DAB"/>
        </svg>
        <svg v-if="checkboxType === 'indeterminate' && state" class="indeterminate-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M6.35416 10.0834H11.6458C11.9514 10.0834 12.2118 9.97574 12.4271 9.76046C12.6424 9.54518 12.75 9.28476 12.75 8.97921C12.75 8.67365 12.6424 8.41324 12.4271 8.19796C12.2118 7.98268 11.9514 7.87504 11.6458 7.87504H6.35416C6.04861 7.87504 5.78819 7.98268 5.57291 8.19796C5.35763 8.41324 5.25 8.67365 5.25 8.97921C5.25 9.28476 5.35763 9.54518 5.57291 9.76046C5.78819 9.97574 6.04861 10.0834 6.35416 10.0834ZM3.16666 17.0417C2.55555 17.0417 2.03472 16.8264 1.60416 16.3959C1.17361 15.9653 0.958328 15.4445 0.958328 14.8334V3.16671C0.958328 2.5556 1.17361 2.03476 1.60416 1.60421C2.03472 1.17365 2.55555 0.958374 3.16666 0.958374H14.8333C15.4444 0.958374 15.9653 1.17365 16.3958 1.60421C16.8264 2.03476 17.0417 2.5556 17.0417 3.16671V14.8334C17.0417 15.4445 16.8264 15.9653 16.3958 16.3959C15.9653 16.8264 15.4444 17.0417 14.8333 17.0417H3.16666ZM3.16666 14.8334H14.8333V3.16671H3.16666V14.8334Z" fill="#93D5C6"/>
        </svg>
        <svg v-if="checkboxType !== 'indeterminate' && state" class="selected-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M8.83333 10.8334L7.1875 9.18754C6.97916 8.97921 6.72222 8.87504 6.41666 8.87504C6.11111 8.87504 5.85416 8.97921 5.64583 9.18754C5.4375 9.39587 5.33333 9.65282 5.33333 9.95837C5.33333 10.2639 5.4375 10.5209 5.64583 10.7292L8.0625 13.1459C8.28472 13.3681 8.54166 13.4792 8.83333 13.4792C9.125 13.4792 9.38194 13.3681 9.60416 13.1459L14.3958 8.35421C14.6042 8.14587 14.7083 7.88893 14.7083 7.58337C14.7083 7.27782 14.6042 7.02087 14.3958 6.81254C14.1875 6.60421 13.9306 6.50004 13.625 6.50004C13.3194 6.50004 13.0625 6.60421 12.8542 6.81254L8.83333 10.8334ZM4.16666 18.0417C3.55555 18.0417 3.03472 17.8264 2.60416 17.3959C2.17361 16.9653 1.95833 16.4445 1.95833 15.8334V4.16671C1.95833 3.5556 2.17361 3.03476 2.60416 2.60421C3.03472 2.17365 3.55555 1.95837 4.16666 1.95837H15.8333C16.4444 1.95837 16.9653 2.17365 17.3958 2.60421C17.8264 3.03476 18.0417 3.5556 18.0417 4.16671V15.8334C18.0417 16.4445 17.8264 16.9653 17.3958 17.3959C16.9653 17.8264 16.4444 18.0417 15.8333 18.0417H4.16666ZM4.16666 15.8334H15.8333V4.16671H4.16666V15.8334Z" fill="#93D5C6"/>
        </svg>
    </div>
</template>

<style scoped>
.checkbox {
    padding: 8px;
    background: transparent;
    cursor: pointer;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox:hover {
    background: var(--Statelayers-onsurface-opacity08);
}

.checkbox.error path {
    fill: var(--System-Error);
}

.checkbox.error:hover {
    background: var(--Statelayers-error-opacity08);
}

.checkbox.selected:hover {
    background: var(--Statelayers-primary-opacity08);
}
</style>
