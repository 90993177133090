<template>
    <div class="two-factor-protection">
        <BaseIconTitle
            :title="'2-factor Protection'"
            :material-icon="'qr_code_2_add'"
        />
        <div class="qr-desc">
            <h5 class="body__medium">
                {{ user.otp ? 'Google 2-factor authenticator is active' : 'Activate code for Google Autenticator' }}
            </h5>
            <p class="body__small desc">
                {{ user.otp ? 'If you wish to disable protection, enter the authenticator app code' : 'Scan or manually enter the QR code in Google 2FA to add a token' }}
            </p>
            <div v-if="!user.otp" class="qr-panel">
                <div class="qr">
                    <QrcodeVue :value="otpEnableData.otpauth_url" :size="100"/>
                </div>
                <div class="qr-info">
                    <h6 class="label__medium">QR Code</h6>
                    <p class="code body__extrasmall">{{ otpEnableData.base32 }}</p>
                    <BaseButtonSmall
                        class="copy"
                        :button-text="'Copy Code'"
                        :button-style="'tonal-secondary'"
                        @click.native="copy(otpEnableData.base32)"
                    />
                </div>
            </div>
        </div>
        <div class="activate-desc">
            <h5 v-if="!user.otp" class="body__medium">Activate Authenticator and enter a generated code in field
                below</h5>
            <BaseInput
                v-model="codeCheck"
                :info="'Enter the 6-digit code generated'"
                :type="'password'"
            />
        </div>
        <div class="activate">
            <BaseButton
                :button-text="user.otp ? 'Disable Two-Factor Protection' : 'Activate 2FA Protection'"
                :button-style="'filled-primary'"
                @click.native="makeAction"
            />
        </div>
    </div>
</template>

<script>
import BaseIconTitle from "@/new-design/shared/ui/titles/BaseIconTitle.vue"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"
import {QR_BRAND_TITLE, QR_TITLE} from "@/common/config"
import QrcodeVue from "qrcode.vue"
import {mapGetters} from "vuex"
import {OTP_ACTIVATE, OTP_DISABLE} from "@/store/actions.type"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"

export default {
    name: "TwoFactorProtection",
    components: {BaseButton, BaseInput, QrcodeVue, BaseButtonSmall, BaseIconTitle},
    data() {
        return {
            qrTitle: QR_TITLE,
            qrTitleBrand: QR_BRAND_TITLE,
            code: '',
            codeCheck: '',
        }
    },
    computed: {
        ...mapGetters(['otpEnableData', 'user'])
    },
    methods: {
        copy(data) {
            navigator.clipboard.writeText(data)
        },
        makeAction() {
            if (this.user.otp) {
                this.disableOtp()
            } else {
                this.activateOtp()
            }
        },
        activateOtp() {
            this.$store.dispatch(OTP_ACTIVATE, {
                code: this.codeCheck
            })

            this.codeCheck = ''
        },
        disableOtp() {
            this.$store.dispatch(OTP_DISABLE, {
                code: this.codeCheck
            })

            this.codeCheck = ''
        },
    },
    watch: {
        otpEnableData(val) {
            if (this.qrTitle) {
                val.otpauth_url = val.otpauth_url.replace(this.qrTitleBrand, this.qrTitle)
            }
            this.code = val.base32
        }
    }
}
</script>

<style scoped>
.icon-title {
    padding-bottom: 16px;
}

h5 {
    color: var(--System-OnSurface);
}

.desc {
    color: var(--System-OnSurfaceVariant);
}

.activate-desc {
    padding: 16px 0;
}

.qr-desc {
    padding-top: 16px;
}

.qr-panel {
    padding: 16px;
    margin-top: 16px;
    background-color: var(--System-Surface1);
    border-radius: var(--Corner-Extra-small);
    display: flex;
    gap: 16px;
}

.qr {
    padding: 10px;
    border-radius: 2px;
    background: var(--white);
}

.qr-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.copy {
    flex: 0 1;
    width: fit-content;
}

h6 {
    color: var(--System-OnSurfaceVariant);
}

.activate {
    padding: 16px 0 0;
    width: 100%;
    text-align: right;
}
</style>
