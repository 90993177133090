<template>
    <div class="tabs" @click="updateActiveTab" :style="tabsWrapperStyle">
        <div v-for="(tab, idx) in tabs" 
            :key="idx" class="item title__medium"
            :class="{ active: tab === tabs[activeTab] }" 
            :style="tabStyle"
            :data-index="idx">{{ tab }}</div>
    </div>
</template>

<script>
export default {
    name: "BaseTabs",
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        tabsWrapperStyle: {
            type: Object,
        },
        tabStyle: {
            type: Object,
        },
        activeTab: {
            type: [String, Number],
            default: 0
        }
    },
    methods: {
        updateActiveTab(e) {
            let activeTabIndex = e.target.getAttribute('data-index')
            if (activeTabIndex) {
                this.$emit('updateActiveTab', activeTabIndex);
            }
        },
    }
}
</script>

<style scoped>
.tabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    border-bottom: 1px solid #2C334099;
    width: 100%;    
}

.item {
    width: 100%;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--System-OnSurfaceVariant);
    text-align: center;
    border-bottom: 1px solid var(--System-surface5, #252B36);
    transition: all .3s ease;
    word-break: break-word;
}

.item::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--System-Primary);
    opacity: 0;
    transition: all .3s ease;
}

.item.active {
    color: var(--System-Primary);
}

.item.active::after {
    opacity: 1;
}
</style>